<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <SubscriptionModal content-id="7"/>
    <div class="containe-fluid">
      <div class="row">
        <div class="col-12" id="cards">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12" v-for="(img, key) in images" :key="key">
              <img class="img-fluid" :src="require('@/assets/Images/Exercices/' + img)" alt="img" />
            </div>
          </div>
          <div class="row justify-content-center exer">
            <div class="col-12 title">
              <h1>{{ locale[lang].EXERCICES.QUESTIONS.TITLE }}</h1>
              <h3 class="sub-red">{{ locale[lang].EXERCICES.QUESTIONS.SUBTITLE }}</h3>
            </div>
          </div>
          <div class="row items">
            <div class="col-12">
              <div data-aos="fade-right" class="row" v-for="(text, key) in locale[lang].EXERCICES.QUESTIONS.ITEMS"
                :key="key">
                <FaqItem :text="text" :img="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row guide">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <h3>
                {{ locale[lang].EXERCICES.LE_GUIDE.TITLE }}
              </h3>
              <img v-if="!mobile" class="img-fluid" src="@/assets/Images/Home/Gif-A.gif" alt="gif-a" />
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12" id="text">
              <p>
                {{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_1_1}} <br />
                {{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_1_2}}
              </p>
              <p>
                {{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_2_1}}
                <span>{{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_2_2}}</span>
                {{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_2_3}}
              </p>
              <p>
                {{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_3_1}}
                <span>{{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_3_2}}</span>
                {{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_1_3}}
              </p>
              <p class="check" v-for="(check, key) in locale[lang].EXERCICES.LE_GUIDE.CONTENT.CHECKS" :key="key"><i
                  class="fas fa-check"></i> {{ check }}</p>
              <p>
                {{locale[lang].EXERCICES.LE_GUIDE.CONTENT.TEXT_4}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="guide">
        <div class="col-12">
          <div class="row justify-content-center exer" data-aos="fade-right">
            <div class="col-12 title">
              <h1>{{ locale[lang].EXERCICES.VOTRE.TITLE }}</h1>
            </div>
          </div>
          <div class="row pad">
            <div class="col-1" v-if="!mobile"></div>
            <div class="col-lg-10 col-md-10 col-sm-12 col-12 votre-g">
              <div class="text-votre">
                <img class="img-fluid" src="@/assets/Images/Exercices/Img-800exercices.png" alt="" />
                <p>
                  {{ locale[lang].EXERCICES.VOTRE.CONTENT.TEXT_1 }}
                </p>
                <p>
                  {{ locale[lang].EXERCICES.VOTRE.CONTENT.TEXT_2 }}
                </p>
                <p>
                  {{ locale[lang].EXERCICES.VOTRE.CONTENT.TEXT_3 }}
                </p>
                <p>
                  <strong>{{ locale[lang].EXERCICES.VOTRE.CONTENT.TEXT_4 }}</strong>
                </p>
                <p>
                  <strong>{{ locale[lang].EXERCICES.VOTRE.CONTENT.TEXT_5 }}</strong>
                </p>
                <button class="btn" @click="goModal">
                  {{ locale[lang].EXERCICES.VOTRE.CONTENT.BUTTON }}
                </button>
              </div>
            </div>
            <div class="col-1" v-if="!mobile"></div>
          </div>
        </div>
      </div>

      <div class="row" id="satisfaction">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="garantie">
          <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].GARANTIE_IMG)" alt="garantie" />
          <h2> {{ locale[lang].EXERCICES.GARANTIE.TITLE }} </h2>
          <p>
            {{ locale[lang].EXERCICES.GARANTIE.SUBTITLE }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="paiement">
          <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].PAY_IMG)" alt="paiement" />
          <h2> {{ locale[lang].EXERCICES.PAIEMENT.TITLE }} </h2>
          <p>
            {{ locale[lang].EXERCICES.PAIEMENT.SUBTITLE }}
          </p>
        </div>
        <div class="col-12" id="page">
          <h3>
            {{ locale[lang].EXERCICES.CONTACT.TEXT_1 }}
            <br />
            {{ locale[lang].EXERCICES.CONTACT.TEXT_2 }}
            <a href="javascript:;" @click="$router.push({ name: 'Contacto' })">{{ locale[lang].EXERCICES.CONTACT.LINK }}</a>
          </h3>
        </div>
      </div>

      <div class="row" id="exemples">
        <div class="col-12">
          <div class="row justify-content-center exer">
            <div class="col-12 title" data-aos="fade-right">
              <h1>{{ locale[lang].EXERCICES.EXAMPLES.TITLE }}</h1>
            </div>
            <h3>
              {{ locale[lang].EXERCICES.EXAMPLES.SUBTITLE }}
            </h3>
          </div>
          <div class="row pad" v-if="!mobile">
            <div class="col-lg-6 col-md-6 col-sm-12" v-for="(card, key) in locale[lang].EXERCICES.EXAMPLES.EXERCICES1"
              :key="key">
              <NotiCard class="notiCard" :artInfo="card" />
            </div>
          </div>
          <div class="row justify-content-center cardsR" v-else>
            <ExerciceCardsM class="notiCard" :infoCards="locale[lang].EXERCICES.EXAMPLES.EXERCICES1" :category="true" />
          </div>
          <div class="row justify-content-center exer">
            <div class="col-12 title" data-aos="fade-right">
              <h1>{{ locale[lang].EXERCICES.EXAMPLES.TITLE }}</h1>
            </div>
            <h3>
              {{ locale[lang].EXERCICES.EXAMPLES.SUBTITLE }}
            </h3>
          </div>
          <div class="row pad" v-if="!mobile">
            <div class="col-lg-6 col-md-6 col-sm-12 cardVideo"
              v-for="(card, key) in locale[lang].EXERCICES.EXAMPLES.EXERCICES2" :key="key">
              <NotiCard class="notiCard" :artInfo="card" />
            </div>
          </div>
          <div class="row justify-content-center cardsR" v-else>
            <ExerciceCardsM class="notiCard" :infoCards="locale[lang].EXERCICES.EXAMPLES.EXERCICES2" :category="true" />
          </div>
        </div>
      </div>

      <div class="row carousel">
        <OpinionCarousel :mobile="mobile" :title="carouselTitle" id="carousel" />
      </div>

      <div class="row pad" id="last">
        <div class="col-12 votre-g" id="end">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-right">
              <h1> {{ locale[lang].EXERCICES.END_PART.TEXT }} </h1>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-left">
              <button class="btn" @click="goModal"> {{ locale[lang].EXERCICES.END_PART.BUTTON }} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container">
      <Footer />
    </div>
  </div>
</template>

<script>
import NotiCard from "@/components/NotiCard.vue";
import FaqItem from "@/components/FaqItem.vue";
import OpinionCarousel from "@/components/OpinionCarousel.vue";
import ExerciceCardsM from "@/components/ExerciceCardsM.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
import modalMixin from "@/mixins/modalMixin";

export default {
  mixins: [modalMixin],
  components: {
    NotiCard,
    FaqItem,
    OpinionCarousel,
    ExerciceCardsM,
    NavBar,
    Footer,
  },
  created() {
    var w = window.innerWidth;
    this.carouselTitle = this.locale[this.lang].EXERCICES.OPINION_TITLE;
    if (w <= 1024) {
      this.mobile = true;
      this.carouselTitle = this.locale[this.lang].EXERCICES.OPINION_MOBILE_TITLE;
    }
  },
  methods: {
    goModal() {
      if (this.locale[this.lang].EXERCICES.VOTRE.CONTENT.BUTTON_SHOP_LINK != null) {
        window.open(this.locale[this.lang].EXERCICES.VOTRE.CONTENT.BUTTON_SHOP_LINK, "_blank")
      } else {
        window.open(this.locale[this.lang].MODAL_LINKS.EXERCICES, "_blank")
      }
    }
  },
  data() {
    return {
      carouselTitle: '',
      mobile: false,
      img: "information@2x-red.png",
      images: ["exercices1.png", "exercices2.png", "exercices3.png"],
    };
  },
};
</script>

<style scoped>
.exer {
  margin: 100px 0px;
}

#nav-container {
  background-color: #0f1010;
}

.title {
  position: relative;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  top: 120%;
  right: 47%;
  opacity: 1;
}

.title h1 {
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.title h3,
.exer h3 {
  font: normal normal 500 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #fc202e;
  opacity: 1;
}

.exer .sub-red {
  color: #fc202e !important;
}

.exer h3 {
  color: #0f1010 !important;
  margin-top: 45px;
}

#cards {
  background-image: url("../assets/Images/Exercices/Enmascarar grupo 147@2x.png"),
    url("../assets/Images/Exercices/Trazado 2465.png"),
    url("../assets/Images/Exercices/Elipse 117.png");
  background-repeat: no-repeat;
  background-size: 100%, auto, auto;
  background-position: 0 -170px, 5% 55%, 90% 80%;
  padding: 100px 290px;
}

#guide {
  background-image: url("../assets/Images/Exercices/Grupo 980.png"),
    url("../assets/Images/Exercices/Trazado 2687.png"),
    url("../assets/Images/Exercices/Trazado 2677.png"),
    url("../assets/Images/Exercices/Trazado 2185.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 11.7% 3%, 10% 45%, 100% 100%, 125% 70%;
}

#exemples {
  background-image: url("../assets/Images/Exercices/Grupo 872.png"),
    url("../assets/Images/Training/Enmascarar grupo 150.png"),
    url("../assets/Images/Exercices/Enmascarar grupo 151.png"),
    url("../assets/Images/Exercices/Elipse 161.png"),
    url("../assets/Images/Training/Elipse 117.png");
  background-repeat: no-repeat;
  background-size: auto, auto, auto, 290px, 290px;
  background-position: 10% 62%, 100% 45%, 0% 90%, 93% 97%, 10% 20%;
}

#last {
  background-image: url("../assets/Images/Exercices/Grupo 1001.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 14% 0;
  padding: 11% 290px 5% 290px;
}

#cards img {
  border-radius: 10px;
  margin-bottom: 20px;
}

#exemples >>> .card {
  border: none;
  height: 92%;
}

#exemples >>> img {
  height: 370px !important;
}

#exemples .cardVideo >>> .card-body::before {
  content: "";
  position: absolute;
  top: -75%;
  left: 44%;
  background-image: url("../assets/Images/play-icon.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 57px;
  height: 65px;
}

.pad {
  padding: 0 290px;
}

.init >>> .category {
  top: -35%;
}

.init >>> .read-more,
#exemples >>> .read-more {
  display: none;
}

.items >>> .sm-item {
  cursor: unset;
}

.items >>> .item h3 {
  text-align: left;
  font: normal normal 500 22px/30px Montserrat !important;
  letter-spacing: 0.44px;
  color: #242424;
  opacity: 1;
  white-space: initial;
}

.notiCard >>> .card-body {
  height: 340px;
}

.notiCard >>> .category {
  top: -8%;
}

.notiCard >>> .read-more {
  position: absolute;
  bottom: 10%;
}

.votre-g {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 30px;
  opacity: 1;
}

#end {
  box-shadow: 0px 3px 30px #00000014 !important;
  padding: 35px 70px;
  margin-bottom: 200px;
}

#end h1 {
  text-align: left;
  font: normal normal 800 38px/50px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  text-transform: uppercase;
  opacity: 1;
}

#end button {
  margin-top: 10px !important;
}

.text-votre {
  padding: 60px 25px;
}

.text-votre p {
  text-align: center;
  font: normal normal 500 22px/30px Montserrat;
  letter-spacing: 0.44px;
  color: #242424;
  margin-bottom: 35px;
}

.text-votre img {
  margin-bottom: 60px;
}

.btn {
  font: normal normal bold 22px/27px Montserrat;
  padding: 25px 100px;
  margin-top: 40px;
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-color: #ffffff;
  /* border: 3px solid #0f1010 !important; */
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 15px;
  text-align: center;
  font: normal normal bold 26px/32px Montserrat;
  color: #0f1010;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
}

.btn:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 20px;
}

.img-fluid {
  object-fit: cover;
}

#contact h2 {
  text-align: center;
  font: normal normal 600 35px/50px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}

#contact {
  padding: 20% 315px;
  height: 630px;
}

#contact h2 a {
  color: #f9334a !important;
  text-decoration: none;
}

#contact h2 a:hover {
  text-decoration: underline;
}

.carousel {
  background-image: url("../assets/Images/Formation/Grupo 1109.png"),
    url("../assets/Images/Saison/Grafismo1.png");
  background-repeat: no-repeat;
  padding: 10% 0 20% 0 !important;
  background-size: 500px 223px, auto;
  background-position: 50% 90%, 0 50%;
}

.carousel >>> .title {
  padding-right: 0;
  margin-bottom: 100px;
}

.carousel >>> .title h3 {
  font-size: 37px !important;
}

.carousel >>> .title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  top: 170%;
  right: 4%;
  opacity: 1;
}

.fa-check {
  color: #f8394f;
}

.guide {
  background-image: url("../assets/Images/Exercices/Enmascarar grupo 148@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 5%;
  padding-top: 260px;
  height: 1200px;
}

.guide h3 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: 40%;
}

.guide p {
  text-align: left;
  font: normal normal 500 22px/29px Montserrat;
  letter-spacing: 0.44px;
  color: #ffffff;
}

.guide span {
  font-weight: bold !important;
}

#text {
  padding-right: 10.5%;
}

.check {
  font-weight: 600 !important;
}

#satisfaction {
  background-image: url("../assets/Images/Practice/Enmascarar grupo 149.png"),
    url("../assets/Images/Cycles/Enmascarar grupo 149.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 0, 50% 100%;
  padding: 14% 0%;
}

#satisfaction #garantie {
  padding-left: 12%;
  padding-right: 6%;
  position: relative;
}

#satisfaction #garantie::after {
  content: "";
  position: absolute;
  height: 75%;
  left: 99%;
  top: 20%;
  border-right: 1px solid rgba(255, 255, 255, 0.7);
}

#satisfaction #paiement {
  padding: 7% 8% 0 5%;
}

#satisfaction h2 {
  text-align: center;
  font: normal normal 800 30px/37px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 27px 0;
}

#satisfaction p {
  font: normal normal 500 22px/30px Montserrat;
  letter-spacing: 0.44px;
  text-align: center;
  color: #ffffff !important;
}

#page {
  padding: 8% 10% 0 10%;
}

#page h3 {
  text-align: center;
  font: normal normal 600 35px/50px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #ffffff;
}

#page h3 a {
  color: #f9334a;
}

@media (max-width: 1920px) {
  .items >>> .container-fluid.item {
    width: auto;
  }
}

@media (max-width: 1850px) {
  #last {
    padding: 6% 100px 4% 100px;
  }
}

@media (max-width: 1600px) {
  #cards {
    padding: 100px 70px;
  }

  .guide {
    height: auto;
    background-size: cover;
    padding: 30% 0 18% 0;
  }

  .pad {
    padding: 0 100px;
  }

  #contact {
    padding: 16% 315px;
  }

  .carousel {
    background-size: 500px 223px, 40%;
  }

  #end {
    padding: 20px 50px;
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  #nav-container {
    background-color: transparent;
  }

  #cards {
    background-image: none;
    padding: 10px 40px;
  }

  .exer {
    margin-top: 56px;
    margin-bottom: 37px;
  }

  .exer h3 {
    margin-top: 20px;
  }

  .title {
    padding: 0;
  }

  .title::after {
    top: 105%;
    right: 38%;
  }

  #notiCard-1,
  #notiCard-2 {
    display: none;
  }

  .items >>> .item {
    padding: 11px 26px 11px 14px;
  }

  .cardsR {
    padding: 0 10%;
  }

  .items >>> .item img {
    height: 45px;
    width: 45px;
    margin-right: 16px;
  }

  .items >>> .item h3,
  .guide p {
    font-size: 16px !important;
    line-height: 18px !important;
    letter-spacing: 0.24px;
  }

  .guide h3 {
    font-size: 25px;
    line-height: 30px;
    margin-left: 0;
    text-align: center;
  }

  #text {
    padding-right: 0;
    position: relative;
    margin-top: 56px;
  }

  #text::before {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 60px;
    top: -35px;
    right: 43%;
    opacity: 1;
  }

  .guide {
    background-image: url("../assets/Images/Exercices/Enmascarar grupo 207.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    padding: 20% 5% 18% 5%;
  }

  #guide {
    background-image: none;
  }

  .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  #guide .pad {
    justify-content: center;
    padding: 0 5%;
  }

  #guide .title::after {
    top: 125%;
    right: 42%;
  }

  .votre-g {
    background-color: transparent;
    box-shadow: none;
  }

  .text-votre {
    padding: 30px 0;
    margin-bottom: 30px;
  }

  .text-votre img {
    margin-bottom: 40px;
  }

  .text-votre p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.24px;
    text-align: left;
    margin-bottom: 15px;
  }

  #satisfaction {
    background-image: url("../assets/Images/Cycles/Satisfaction Garantie.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
  }

  #satisfaction #garantie {
    padding: 0 6%;
    margin-bottom: 50px;
  }

  #satisfaction #garantie::after {
    border-right: 0;
  }

  #paiement img {
    max-width: 50%;
  }

  #paiement h2 {
    font-size: 18px;
    line-height: 26px;
  }

  #page {
    padding: 38% 9% 8%;
  }

  #page h3 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.12px;
  }

  #page h3 br {
    display: none;
  }

  #exemples {
    background-image: none;
  }

  #exemples >>> img {
    height: auto !important;
  }

  #exemples h1 {
    font-size: 21px;
    line-height: 25px;
    padding: 0 10%;
  }

  #exemples h3 {
    font-size: 16px;
    line-height: 20px;
    color: #707070 !important;
    padding: 0 5%;
  }

  #exemples .pad {
    padding: 0;
  }

  #exemples .cardVideo {
    padding: 0 30px;
  }

  #exemples .title::after {
    right: 45%;
  }

  #exemples .notiCard >>> .card-body {
    height: auto;
    padding: 25px 16px;
  }

  #exemples .notiCard >>> .card-title {
    font-size: 16px;
    line-height: 16px;
  }

  #exemples .notiCard >>> .category {
    padding: 8px 16px 0px 16px;
  }

  #exemples .notiCard >>> .card-sub,
  #exemples .notiCard >>> .category p {
    font-size: 12px;
    line-height: 16px;
  }

  #exemples .notiCard >>> .category p {
    margin-bottom: 1em;
  }

  #exemples .notiCard >>> .card-text {
    font-size: 14px;
    line-height: 20px;
    color: #aeaeae;
  }

  #exemples .notiCard >>> .read-more {
    font-size: 14px;
    line-height: 14px;
    position: relative;
    bottom: unset;
  }

  .carousel {
    background-image: none;
    padding: 0 !important;
  }

  #carousel >>> .title {
    padding: 0 10%;
    margin-top: 100px;
    margin-bottom: 30px;
    position: relative;
  }

  #carousel >>> .title::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 30px;
    left: 47%;
    bottom: -30%;
    opacity: 1;
  }

  #carousel >>> .title h3 {
    text-align: center;
    font-size: 21px !important;
    line-height: 25px;
  }

  #carousel >>> .opinion-card {
    padding: 15px 16px;
  }

  #carousel >>> .carousel-indicators {
    left: 0;
    bottom: -5%;
  }

  #cardsM >>> .carousel-indicators li,
  #carousel >>> .carousel-indicators li,
  #notiCarousel >>> .carousel-indicators li {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    opacity: 1;
  }

  #carousel >>> .left-img {
    display: flex;
  }

  #carousel >>> .img-cm {
    width: 46px;
    height: 46px;
    margin-right: 14px;
  }

  #carousel >>> p {
    text-align: left;
    font: italic normal 500 12px/21px Montserrat;
    letter-spacing: 0px;
    color: #444444;
  }

  #carousel >>> .body h3 {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 0;
  }

  #carousel >>> .body h5 {
    font-size: 12px;
    line-height: 22px;
  }

  #last {
    background-image: none;
    padding: 0;
  }

  #end {
    background-color: transparent;
    box-shadow: none !important;
    padding: 100px 0;
  }

  #end h1 {
    text-align: center;
    font: normal normal 800 21px/25px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    text-transform: initial;
    padding: 0 35px;
  }

  .btn {
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    padding: 14px 55px 16px 55px;
  }

  #end button {
    margin-top: 35px !important;
  }
}

@media (max-width: 375px) {
  .guide {
    background-position: 50% 0;
    padding: 26% 5%;
  }

  #text {
    padding-right: 30px;
  }

  #contact {
    background-size: auto;
    background-position: 50% 0;
    padding: 20% 10%;
  }
}
</style>
